define([
    'lodash',
    'prop-types',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/utils/dataFetchUtil'
], function (
    _,
    PropTypes,
    propsSelectorsUtils,
    dataFetchUtil
) {
    'use strict';

    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;

    const geo = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state, 'siteData.rendererModel.geo');
    }, 'geo');

    const contactInfo = createGlobalSantaType(PropTypes.object, function (state) {
        return _.get(state, 'siteData.rendererModel.siteMetaData.contactInfo');
    }, 'contactInfo');

    const configuration = createGlobalSantaType(PropTypes.object, function (state) {
        return _.get(state, 'siteData.rendererModel.siteMetaData.quickActions.configuration');
    }, 'configuration');

    const userId = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'userId']);
    }, 'userId');

    const colorScheme = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state, 'siteData.rendererModel.siteMetaData.quickActions.colorScheme');
    }, 'colorScheme');

    const socialLinks = createGlobalSantaType(PropTypes.string, function (state) {
        return _.get(state, 'siteData.rendererModel.siteMetaData.quickActions.socialLinks');
    }, 'socialLinks');

    const metaSiteId = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'metaSiteId']);
    }, 'metaSiteId');

    const useSandboxInHTMLComp = createGlobalSantaType(PropTypes.bool, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'useSandboxInHTMLComp']);
    }, 'useSandboxInHTMLComp');

    const siteId = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'siteInfo', 'siteId']);
    }, 'siteId');

    const documentType = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'siteInfo', 'documentType']);
    }, 'documentType');

    const siteTitleSEO = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'siteInfo', 'siteTitleSEO']);
    }, 'siteTitleSEO');

    const clientSpecMap = createGlobalSantaType(PropTypes.object, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'clientSpecMap']);
    }, 'clientSpecMap');

    const languageCode = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'languageCode']);
    }, 'languageCode');

    const siteMediaToken = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getSiteMediaToken();
    }, 'siteMediaToken');

    const mediaAuthToken = createGlobalSantaType(PropTypes.string, function (state) {
        return state.siteData.getMediaAuthToken();
    }, 'mediaAuthToken');

    const premiumFeatures = createGlobalSantaType(PropTypes.array, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'premiumFeatures']);
    }, 'premiumFeatures');

    const currency = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'sitePropertiesInfo', 'currency']);
    }, 'currency');

    const timeZone = createGlobalSantaType(PropTypes.string, function (state) {
        return dataFetchUtil.get(state, ['rendererModel', 'sitePropertiesInfo', 'timeZone']);
    }, 'timeZone');

    return {
        geo,
        siteMetaData: {
            contactInfo,
            quickActions: {
                configuration,
                socialLinks,
                colorScheme
            }
        },
        documentType,
        siteTitleSEO,
        userId,
        metaSiteId,
        useSandboxInHTMLComp,
        siteId,
        clientSpecMap,
        languageCode,
        siteMediaToken,
        mediaAuthToken,
        premiumFeatures,
        currency,
        timeZone
    };
});
