define([
    'lodash',
    'prop-types',
    'santa-components',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/commonSantaTypes'
], function (
    _,
    PropTypes,
    santaComponents,
    propsSelectorsUtils,
    commonSantaTypes
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const SLIDES_COMPONENT_TYPES = ['wysiwyg.viewer.components.BoxSlideShowSlide',
        'wysiwyg.viewer.components.StripContainerSlideShowSlide',
        'wysiwyg.viewer.components.StateBoxState',
        'wysiwyg.viewer.components.StateBoxFormState',
        'wysiwyg.viewer.components.StateStripState'
    ];

    const slidesIndexes = createComponentSantaType(PropTypes.array, function (state, props) {
        const displayedDAL = state.siteAPI.getDisplayedDAL();
        const pointers = state.siteAPI.getPointers();
        const pagePointer = pointers.components.getPage(props.rootId, state.siteData.getViewMode());

        return _.transform(props.structure.components, function (acc, childId, i) {
            const childPointer = pointers.components.getComponent(childId, pagePointer);
            const componentTypePointer = pointers.getInnerPointer(childPointer, 'componentType');
            const componentType = displayedDAL.get(componentTypePointer);
            if (_.includes(SLIDES_COMPONENT_TYPES, componentType)) {
                acc.push(i);
            }
        }, []);
    });

    const canAutoPlay = createComponentSantaType(PropTypes.bool, function (state, props) {
        return !commonSantaTypes.isZoomOpened.fetch(state, props) &&
            state.fetchSantaType(santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed, state, props) &&
            !!state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props).autoPlay &&
            slidesIndexes.fetch(state, props).length > 1;
    });

    const startAutoPlayInViewport = createComponentSantaType(PropTypes.func, (state, props) =>
        typeof window !== 'undefined' ? (callback, interval) =>
            setInterval(() => {
                const viewportStates = commonSantaTypes.viewportStates.fetch(state, props);
                if (!viewportStates || !viewportStates.in) {
                    return;
                }
                const isZoomOpened = commonSantaTypes.isZoomOpened.fetch(state, props);
                if (isZoomOpened) {
                    return;
                }
                callback();
            }, interval) :
            _.noop
    );

    const stopAutoPlayInViewport = createComponentSantaType(PropTypes.func, function () {
        return typeof window !== 'undefined' ? function (timeoutId) {
            clearInterval(timeoutId);
        } : _.noop;
    });

    const slideStyle = createComponentSantaType(PropTypes.object, function (state, props) {
        const {siteData, siteAPI} = state;
        const style = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.style, state, props);

        const pointers = siteAPI.getPointers();
        const displayedDAL = siteAPI.getDisplayedDAL();
        const pagePointer = pointers.components.getPage(props.rootId, siteData.getViewMode());
        const compPointer = pointers.components.getComponent(props.structure.id, pagePointer);
        const slideShowPointer = pointers.components.getParent(compPointer);
        const slideShowHeightPointer = pointers.getInnerPointer(slideShowPointer, ['layout', 'height']);
        const slideShowHeight = displayedDAL.get(slideShowHeightPointer);

        return _.defaults({minHeight: slideShowHeight}, style);
    });

    return {
        slideStyle,
        canAutoPlay,
        slidesIndexes,
        startAutoPlayInViewport,
        stopAutoPlayInViewport
    };
});
