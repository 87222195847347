define(['lodash', 'prop-types', 'santaProps/utils/propsSelectorsUtils'], function (_, PropTypes, propsSelectorsUtils) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const MODAL_ASPECT_NAME = 'tpaModalAspect';
    const POPUP_ASPECT_NAME = 'tpaPopupAspect';
    const PUB_SUB_ASPECT_NAME = 'tpaPubSubAspect';
    const POST_MESSAGE_ASPECT_NAME = 'tpaPostMessageAspect';
    const COMP_STATE_ASPECT_NAME = 'tpaCompStateAspect';

    const data = createComponentSantaType(PropTypes.shape({
        isModalOpen: PropTypes.bool,
        queryParams: PropTypes.object
    }), function (state, props) {
        const modalAspectData = state.siteAPI.getAspectGlobalData(MODAL_ASPECT_NAME);
        const compStateAspectData = state.siteAPI.getAspectComponentData(COMP_STATE_ASPECT_NAME, _.get(props, 'structure.id'));
        return {
            isModalOpen: !!_.get(modalAspectData, 'modalStructure'),
            queryParams: _.get(compStateAspectData, 'queryParams')
        };
    });

    const showModal = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(MODAL_ASPECT_NAME);
        return aspect.showModal;
    }, 'showModal');

    const removeModal = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(MODAL_ASPECT_NAME);
        return aspect.removeModal;
    }, 'removeModal');

    const showPopup = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POPUP_ASPECT_NAME);
        return aspect.showPopup;
    }, 'showPopup');

    const removePopup = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POPUP_ASPECT_NAME);
        return aspect.removePopup;
    }, 'removePopup');

    const removeAllPopups = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POPUP_ASPECT_NAME);
        return aspect.removeAllPopups;
    }, 'removeAllPopups');

    const deleteCompListeners = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(PUB_SUB_ASPECT_NAME);
        return aspect.deleteCompListeners;
    }, 'deleteCompListeners');

    const initialClientSpecMap = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteAPI.getInitialClientSpecMap();
    }, 'initialClientSpecMap');

    const publish = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(PUB_SUB_ASPECT_NAME);
        return aspect.publish;
    }, 'publish');

    const subscribe = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(PUB_SUB_ASPECT_NAME);
        return aspect.subscribe;
    }, 'subscribe');

    const unsubscribe = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(PUB_SUB_ASPECT_NAME);
        return aspect.unsubscribe;
    }, 'unsubscribe');

    const handleTPAMessage = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POST_MESSAGE_ASPECT_NAME);
        return aspect.handleTPAMessage;
    }, 'handleTPAMessage');

    const sendPostMessage = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POST_MESSAGE_ASPECT_NAME);
        return aspect.sendPostMessage;
    }, 'sendPostMessage');

    const callHandler = createGlobalSantaType(PropTypes.func, function (state) {
        const aspect = state.siteAPI.getSiteAspect(POST_MESSAGE_ASPECT_NAME);
        return aspect.callHandler;
    }, 'callHandler');

    const seoHtmlContent = createComponentSantaType(PropTypes.string, function (state, props) {
        return _.get(state.siteData, ['ssr', 'seoTpaModel', 'applicationsSeoData', props.id, 'compData', 'html']);
    });

    return {
        data,
        showModal,
        removeModal,
        showPopup,
        removePopup,
        removeAllPopups,
        deleteCompListeners,
        initialClientSpecMap,
        publish,
        subscribe,
        unsubscribe,
        handleTPAMessage,
        sendPostMessage,
        callHandler,
        seoHtmlContent
    };
});
