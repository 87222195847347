define([
    'lodash',
    'prop-types',
    'santa-components',
    'coreUtils',
    'santaProps/utils/propsSelectorsUtils',
    'santaProps/types/modules/VideoQoS'
], function (
    _,
    PropTypes,
    santaComponents,
    coreUtils,
    propsSelectorsUtils,
    VideoQoS
) {
    'use strict';

    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    const createGlobalSantaType = propsSelectorsUtils.createGlobalSantaType;
    const consts = coreUtils.mediaConsts;


    const playingVideoStates = [consts.playbackTypes.SEEK_PLAYING, consts.playbackTypes.PLAYING];


    function getData(state, props) {
        //todo: remove compData support (ADI still using deprecated stripContainer with compData)
        return state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compDesign, state, props) || state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
    }

    function getPageBackground(state) {
        const currentUrlPageId = state.siteData.getCurrentUrlPageId();
        const pageData = state.siteData.getDataByQuery(currentUrlPageId);
        const device = state.siteData.isMobileView() ? 'mobile' : 'desktop';
        const background = _.get(pageData, ['pageBackgrounds', device, 'ref']) || {};

        const bgOverride = _.get(state.siteData.getRootNavigationInfo(), 'pageBackgroundColorOverride');
        if (bgOverride) {
            background.color = bgOverride;
            background.mediaRef = null;
        }

        return {
            background,
            mediaSizing: _.get(pageData, ['pageBackgrounds', device, 'mediaSizing'], '')
        };
    }

    function getPopupBackground(state, props) {
        const data = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compData, state, props);
        const device = state.siteData.isMobileView() ? 'mobile' : 'desktop';
        return {background: _.get(data, ['pageBackgrounds', device, 'ref'])};
    }

    function getRenderParts(data, state, props) {
        return {
            media: {
                video: VideoQoS.getVideoRenderParts(data, state, props),
                image: []
            }
        };
    }

    const globalImageQuality = createGlobalSantaType(PropTypes.object, function (state) {
        return state.siteData.getGlobalImageQuality();
    }, 'globalImageQuality');

    const playbackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getUrl(data, state, props);
    });

    const playbackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const playbackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getData(state, props);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    const renderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getData(state, props);
        return getRenderParts(data, state, props);
    });

    /**
     * POPUP
     */

    const popupBackgroundData = createComponentSantaType(PropTypes.object, getPopupBackground);

    const popupRenderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPopupBackground(state, props);
        return getRenderParts(data, state, props);
    });

    const popupPlaybackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getUrl(data, state, props);
    });

    const popupPlaybackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const popupPlaybackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPopupBackground(state, props);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    /**
     * SITE BACKGROUND
     */

    const siteBackgroundData = createGlobalSantaType(PropTypes.object, getPageBackground, 'siteBackgroundData');

    const siteBackgroundRenderParts = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPageBackground(state);
        return getRenderParts(data, state, props);
    });

    const siteBackgroundPlaybackUrl = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getUrl(data, state, props);
    });

    const siteBackgroundPlaybackFormat = createComponentSantaType(PropTypes.string, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getPlaybackFormat(data, state, props);
    });

    const siteBackgroundPlaybackConfig = createComponentSantaType(PropTypes.object, function (state, props) {
        const data = getPageBackground(state);
        return VideoQoS.getPlaybackConfig(data, state, props);
    });

    const canVideoPlayInline = createComponentSantaType(PropTypes.bool, function (state, props) {
        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const isMobileDevice = state.fetchSantaType(santaComponents.santaTypesDefinitions.Device.isMobileDevice, state, props);
        if (!isMobileView && !isMobileDevice) {
            return true;
        }
        const {featureDetections} = state.siteAPI.getAspectGlobalData('mediaAspect');
        const playsInline = _.get(featureDetections, 'playsInline', false);
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const enableBackgroundVideo = _.get(compProp, 'enableBackgroundVideo', true);
        return playsInline && enableBackgroundVideo;
    });

    /**
     * PLAYBACK STATES
     */

    /**
     * Get playback state from control components
     * @param state
     * @param props
     * @returns {{}}
     */
    function getStateByPlayerIdCompProp(state, props) {
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const playerState = state.siteAPI.getAspectComponentData('mediaAspect', compProp.playerId);
        return playerState || {};
    }

    /**
     * Get playback state from player components
     * @param state
     * @param props
     * @returns {{}}
     */
    function getStateByCompId(state, props) {
        const compId = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.id, state, props);
        const playerState = state.siteAPI.getAspectComponentData('mediaAspect', compId);
        return playerState || {};
    }

    /**
     * In use only in MediaPlayerPreviewExtension
     */
    const playerPlaybackState = createComponentSantaType(PropTypes.string, function (state, props) {
        const playerState = getStateByCompId(state, props);
        return playerState.playbackState;
    });

    function normalizePlaying(playbackState) {
        if (_.includes(playingVideoStates, playbackState)) {
            return consts.playbackTypes.PLAYING;
        }
        return playbackState;
    }

    const playbackState = createComponentSantaType(PropTypes.string, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return normalizePlaying(playerState.playbackState);
    });

    const fullscreen = createComponentSantaType(PropTypes.bool, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.fullscreen;
    });

    const volume = createComponentSantaType(PropTypes.number, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.volume;
    });

    const muted = createComponentSantaType(PropTypes.bool, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.muted;
    });

    const controlsData = createComponentSantaType(PropTypes.object, function (state, props) {
        const playerState = getStateByPlayerIdCompProp(state, props);
        return playerState.controlsData || {};
    }, true);

    //TODO: merge with canVideoPlayInline santa type when the 'sv_videobox_mobile' experiment is merged
    const enableBackgroundVideo = createComponentSantaType(PropTypes.bool, function (state, props) {
        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const isDesktopDevice = !state.fetchSantaType(santaComponents.santaTypesDefinitions.Device.isTouchDevice, state, props);
        const isTabletDevice = state.siteData.isTabletDevice();
        const isSupportBgVideoDevice = isDesktopDevice || (isTabletDevice && state.siteAPI.isExperimentOpen('sv_enableBackgroundVideoOnTablet'));

        if (isSupportBgVideoDevice && !isMobileView) {
            return true;
        }

        const {featureDetections} = state.siteAPI.getAspectGlobalData('mediaAspect');
        const playsInline = _.get(featureDetections, 'playsInline', false);

        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const enableBackgroundVideoProp = _.get(compProp, 'enableBackgroundVideo', false);

        return playsInline && enableBackgroundVideoProp;
    });

    return {
        globalImageQuality,

        renderParts,
        playbackUrl,
        playbackFormat,
        playbackConfig,
        canVideoPlayInline,
        playerPlaybackState,
        playbackState,
        fullscreen,
        volume,
        muted,
        controlsData,
        enableBackgroundVideo,

        SiteBackground: {
            data: siteBackgroundData,
            renderParts: siteBackgroundRenderParts,
            playbackUrl: siteBackgroundPlaybackUrl,
            playbackFormat: siteBackgroundPlaybackFormat,
            playbackConfig: siteBackgroundPlaybackConfig
        },
        Popup: {
            data: popupBackgroundData,
            renderParts: popupRenderParts,
            playbackUrl: popupPlaybackUrl,
            playbackFormat: popupPlaybackFormat,
            playbackConfig: popupPlaybackConfig
        }
    };
});

