define([
    'lodash',
    'coreUtils',
    'santa-components'
], function (
    _,
    coreUtils,
    santaComponents
) {
    'use strict';

    const consts = coreUtils.mediaConsts;
    const {BrowserFlags, ServiceTopology} = santaComponents.santaTypesDefinitions;


    function getCurrentQualityState(data, state, key) {
        const mediaData = _.get(data, ['background', 'mediaRef']);
        if (isVideoInData(mediaData)) {
            const id = key || mediaData.videoId;
            return _.get(state.siteAPI.getAspectGlobalData('mediaAspect'), id) || {};
        }
        return {};
    }

    function isVideoInData(mediaData) {
        return _.get(mediaData, 'type') === 'WixVideo';
    }

    function getPlaybackConfig(data, state) {
        const mediaData = _.get(data, ['background', 'mediaRef']);
        if (isVideoInData(mediaData) && _.find(mediaData.adaptiveVideo, {format: 'hls'})) {
            return {
                hls: {
                    debug: state.siteData.isQaMode(),
                    capLevelToPlayerSize: true
                }
            };
        }
        return {};
    }

    function getUrl(data, state, props) {
        const mediaData = _.get(data, ['background', 'mediaRef']);
        if (isVideoInData(mediaData)) {
            const playbackFormat = getPlaybackFormat(data, state, props);
            if (playbackFormat === 'hls') {
                const targetFormat = _.find(mediaData.adaptiveVideo, {format: 'hls'});
                return coreUtils.urlUtils.joinURL(state.fetchSantaType(ServiceTopology.adaptiveVideoDomain, state), _.get(targetFormat, 'url'));
            }
        }
        return '';
    }

    function isApplicableForAdaptive(mediaData/*, state, props*/) {
        const hlsItem = _.find(mediaData.adaptiveVideo, {format: 'hls'});
        const aboveMinDuration = parseInt(mediaData.duration, 10) > 60;
        const isTransparentVideo = _.includes(mediaData.mediaFeatures, 'alpha');
        return !isTransparentVideo && hlsItem && aboveMinDuration;
    }

    function getPlaybackFormat(data, state, props) {
        const mediaData = _.get(data, ['background', 'mediaRef']);
        if (isVideoInData(mediaData) && mediaData.qualities) {
            const mediaQualityState = getCurrentQualityState(data, state, mediaData.videoId);
            const isMP4Fallback = _.get(mediaQualityState, 'fallback');
            if (!isMP4Fallback && isApplicableForAdaptive(mediaData, state, props)) {
                return 'hls';
            }
            return 'mp4';
        }
        return '';
    }

    function isVideoEnabledForMobile(state, props) {
        const isMobileView = state.fetchSantaType(santaComponents.santaTypesDefinitions.isMobileView, state, props);
        const isDesktopDevice = !state.fetchSantaType(santaComponents.santaTypesDefinitions.Device.isTouchDevice, state, props);
        const isTabletDevice = state.siteData.isTabletDevice();
        const isSupportBgVideoDevice = isDesktopDevice || (isTabletDevice && state.siteAPI.isExperimentOpen('sv_enableBackgroundVideoOnTablet'));

        if (isSupportBgVideoDevice && !isMobileView) {
            return true;
        }
        const compProp = state.fetchSantaType(santaComponents.santaTypesDefinitions.Component.compProp, state, props);
        const isDisabled = _.get(compProp, 'enableBackgroundVideo') === false;
        return !isDisabled;
    }

    const isTransparentVideoSupported = state => state.fetchSantaType(BrowserFlags.webglVideoTextureSupported, state)() && state.fetchSantaType(BrowserFlags.webglCrossOriginSupported, state)();

    function shouldRenderVideo(mediaData, state, props) {
        const hasVideoAndEnabled = isVideoInData(mediaData) && isVideoEnabledForMobile(state, props);

        if (hasVideoAndEnabled) {
            const isTransparentVideo = _.includes(mediaData.mediaFeatures, 'alpha');

            if (isTransparentVideo) {
                return isTransparentVideoSupported(state);
            }

            return true;
        }

        return false;
    }

    function getVideoRenderParts(data, state, props) {
        let parts = [];
        const mediaData = _.get(data, ['background', 'mediaRef']);
        if (shouldRenderVideo(mediaData, state, props)) {
            let shouldRenderVideoPart = true;
            const qualityState = getCurrentQualityState(data, state, mediaData.videoId);
            const videoErrors = _.get(qualityState, [consts.eventTypes.ERROR]);
            const hasNoVideoError = videoErrors === consts.errorTypes.NO_VIDEO_FOUND;
            if (hasNoVideoError) {
                shouldRenderVideoPart = false;
            } else {
                const playbackFormat = getPlaybackFormat(data, state, props);
                const isHlsFormat = playbackFormat === 'hls';
                const adaptiveQualityState = getCurrentQualityState(data, state, 'adaptiveVideo');
                const isHlsLoaded = _.get(adaptiveQualityState, ['hlsLoaded']);
                if (!playbackFormat || (isHlsFormat && !isHlsLoaded)) {
                    shouldRenderVideoPart = false;
                }
            }
            parts = shouldRenderVideoPart ? ['poster', 'video'] : ['poster'];
        }
        return parts;
    }


    return {
        getPlaybackFormat,
        getUrl,
        getPlaybackConfig,
        getVideoRenderParts
    };
});
